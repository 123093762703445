const menuItems = [
    {
      name: 'Usuarios',
      icon: 'bi-person',
      path: '',
      allowedRoles: ['ROLE_ADMIN'],
      subMenu: [
        { name: 'Usarios Empresas', icon: '', path: '/users-companies', allowedRoles: ['ROLE_ADMIN'] },
        { name: 'Usarios Internos', icon: 'bi-person', path: '/users-internal', allowedRoles: ['ROLE_ADMIN'] },
      ]
    },
    // {
    //   name: 'Settings',
    //   icon: '',
    //   path: '/settings',
    //   allowedRoles: ['ROLE_S'],
    //   subMenu: [
    //     { name: 'User Management', icon: '', path: '/settings/users', allowedRoles: ['ROLE_ADMIN'] },
    //     { name: 'System Logs',icon: '', path: '/settings/logs', allowedRoles: ['ROLE_ADMIN'] },
    //   ],
    // },
    // {
    //   name: 'Profile',
    //   icon: '',
    //   path: '/profile',
    //   allowedRoles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_GUEST'],
    // },
  ];
  
  export default menuItems;