import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token =  sessionStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        debugger
        const navigate = useNavigate();
        
        if (error.response && (403 === error.response.status || (  401 === error.response.status && 'Expired JWT Token'  == error.response.data.message    ))) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('profile');
            localStorage.removeItem('roles');

        }

        navigate('/logout', { replace: true });
    }
);

export default api;