import { Link } from "react-router-dom";

import TemplateHeader from "./TemplateHeader.jsx";
import TemplateAside from "./TemplateAside.jsx";

import './assets/scss/style.scss'
import TemplateFooter from "./TemplateFooter.jsx";



const TemplateAdmin = ({content, title, path }) => {
    return(
        <>
            <TemplateHeader />
            <TemplateAside />

            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>{title}</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">
                                    Inicio
                                </Link>
                                
                                </li>
                            <li className="breadcrumb-item active">{title}</li>
                        </ol>
                    </nav>
                </div>
                <section className="section p-0">
                    <div class="row">
                        {content}
                    </div>
                </section>
            </main>
            
            <TemplateFooter />
        </>
    );
};

export default TemplateAdmin;