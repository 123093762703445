import { Route } from 'react-router-dom';
import { ProtectedRoute } from './routes/ProtectedRoute.jsx';
import Dashboard from "./pages/admin/Dashboard.jsx";
import TemplateAdmin from "./pages/admin/TemplateAdmin.jsx";
import UsersInternal from './pages/admin/UsersInternal.jsx';
import UsersCompanies from './pages/admin/UsersCompanies.jsx';

const userRoutes = (
    <Route element={<ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_ADMIN']} />}>
        <Route path="/dashboard" element={<TemplateAdmin path="/dashboard" title={"Dashboard"}  content={<Dashboard />} />} />
        <Route path="/users-internal" element={<TemplateAdmin path="/users-internal" title={"Usuarios Internos"} content={<UsersInternal  />} />} />
        <Route path="/users-companies" element={<TemplateAdmin path="/users-companies" title={"Usuarios de compañias"} content={<UsersCompanies/>} />} />
    </Route>
);

export default userRoutes;
